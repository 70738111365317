









































































































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import { mapState, mapActions } from "pinia";
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import { minLength, required, requiredIf } from "vuelidate/lib/validators";
import {
  AttachmentBelongsTo,
  AttachmentClient,
  ContentItemType,
  DraftUserExternalLinkContentItemResult,
  FileParameter,
  IUpdateDraftUserExternalLink,
  IUserExternalLinkContentItemResult,
  UpdateDraftUserExternalLink,
  UserExternalLinkClient,
  UserDraftExternalLinkClient,
  UserContentClient,
  ExternalLinkType,
} from "@/api/DoceoApi";

import EditExternalLink from "@/components/contentItems/drafts/externalLinks/EditExternalLink.vue";
import CreateContentPublishConfirm from "@/components/contentItems/drafts/CreateContentPublishConfirm.vue";

import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "DraftUserExternalLinkContentItem",
  components: {
    EditExternalLink,
    UserExternalLinkContentItem: () => import("../../userContent/externalLinks/UserExternalLinkContentItem.vue"),
    CreateContentPublishConfirm,
    ConfirmDialog,
    DoceoIcon,
  },
  mixins: [validationMixin],
  props: {
    contentItem: {
      type: Object as PropType<DraftUserExternalLinkContentItemResult>,
      required: true,
    },
    isEditingPublished: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    contentItemId: null as string | null,
    updatedItem: {} as DraftUserExternalLinkContentItemResult,
    newAttachments: [] as File[],
    newImages: [] as File[],
    newFeaturedImage: null as File | null,
    deletedAttachmentIds: [] as string[],
    deletedImageIds: [] as string[],
    deletedFeaturedImageId: null as string | null,
    publishDate: null as Date | null,
    isPreviewing: false,
    publishDialogOpen: false,
    savingDraft: false,
    publishing: false,
    showValidation: false,
    deleteDialogOpen: false,
    deleting: false,
    DOCEO_ICONS,
  }),
  validations(): any {
    return {
      updatedItem: {
        title: {
          required,
        },
        summary: {
          required,
        },
        authors: {
          required,
          minLength: minLength(1),
        },
        externalLinkType: {
          required,
        },
        externalLink: {
          required,
        },
        voiceOf: {
          required: requiredIf(() => this.hasVoiceOfRole),
        },
        creator: {
          userId: {
            required: requiredIf(() => this.hasAdminRole),
          },
        },
        promotedBy: {
          required: requiredIf(() => this.hasAdminRole),
        },
      },
    };
  },
  async created() {
    if(this.hasSponsorRole) {
      this.isPreviewing = true;
    }
  },
  methods: {
    async uploadNewAttachmentsAndImages() {
      let attachmentClient = new AttachmentClient();

      // upload new attachments
      let newAttachmentIds = [] as string[];
      for (let attachment of this.newAttachments) {
        let fileParameter: FileParameter = {
          data: attachment,
          fileName: attachment.name,
        };
        let attachmentResult = await attachmentClient.createFromFile(AttachmentBelongsTo.ContentItem, fileParameter);
        newAttachmentIds.push(attachmentResult.id);
      }
      let allAttachmentIds = [...newAttachmentIds, ...(this.updatedItem.attachments?.map((a) => a.id) ?? [])];

      // upload new images
      let newImageIds = [] as string[];
      for (let image of this.newImages) {
        let fileParameter: FileParameter = {
          data: image,
          fileName: image.name,
        };
        let imageResult = await attachmentClient.createFromFile(AttachmentBelongsTo.ContentItem, fileParameter);
        newImageIds.push(imageResult.id);
      }
      let allImageIds = [...newImageIds, ...(this.updatedItem.images?.map((a) => a.id) ?? [])];

      // upload featured image
      let newFeaturedImageId = null as string | null;
      if (this.newFeaturedImage) {
        let fileParameter: FileParameter = {
          data: this.newFeaturedImage,
          fileName: this.newFeaturedImage.name,
        };
        let featuredImageResult = await attachmentClient.createFromFile(AttachmentBelongsTo.ContentItem, fileParameter);
        newFeaturedImageId = featuredImageResult.id;
      } else {
        newFeaturedImageId = this.updatedItem.featuredImage?.id ?? null;
      }

      return { allAttachmentIds, allImageIds, newFeaturedImageId };
    },
    async deleteOldAttachmentsAndImages() {
      let attachmentClient = new AttachmentClient();

      // delete attachments that were removed
      for (let attachmentId of this.deletedAttachmentIds) {
        await attachmentClient.delete(attachmentId);
      }

      // delete images that were removed
      for (let imageId of this.deletedImageIds) {
        await attachmentClient.delete(imageId);
      }

      // delete old featured image
      if (this.deletedFeaturedImageId !== null) {
        await attachmentClient.delete(this.deletedFeaturedImageId);
      }
    },

    async save(publish = false) {
      let uploadResult = await this.uploadNewAttachmentsAndImages();

      // update content
      let draftExternalLink: IUpdateDraftUserExternalLink = {
        title: this.updatedItem.title,
        summary: this.updatedItem.summary,
        attachmentIds: uploadResult.allAttachmentIds,
        imageIds: uploadResult.allImageIds,
        featuredImageId: uploadResult.newFeaturedImageId ?? undefined,
        externalLinkType: this.updatedItem.externalLinkType,
        externalLink: this.updatedItem.externalLink,
        publishedOn: this.publishDate ?? this.updatedItem.publishedOn,
        authors: this.updatedItem.authors,
        voiceOf: this.updatedItem.voiceOf,
        userId: this.updatedItem.creator?.userId,
        promotedBy: this.updatedItem.promotedBy,
      };
      let userDraftExternalLinkClient = new UserDraftExternalLinkClient();
      await userDraftExternalLinkClient.updateDraftArticle(this.updatedItem.contentItemId, new UpdateDraftUserExternalLink(draftExternalLink));

      await this.deleteOldAttachmentsAndImages();

      // publish if requested
      if (publish) {
        let userExternalLinkClient = new UserExternalLinkClient();
        await userExternalLinkClient.publishExternalLink(this.updatedItem.contentItemId);
      }
    },

    async deletePost() {
      let userContentClient = new UserContentClient();
      await userContentClient.deleteUserContent(this.updatedItem.contentItemId);
    },

    back() {
      if (this.isEditingPublished) {
        this.$emit("backClicked");
        return;
      }
      if (this.savingDraft || this.publishing) return;
      if (this.isPreviewing && !this.hasSponsorRole) {
        this.isPreviewing = false;
      } else {
        this.$router.go(-1);
      }
    },
    nextClicked() {
      if (!this.isPreviewing) {
        this.isPreviewing = true;
      } else {
        if (!this.validate()) {
          this.isPreviewing = false;
        } else {
          this.publishDialogOpen = true;
        }
      }
    },

    async savePublished() {
      let valid = this.validate();

      if (valid) {
        let contentClient = new UserExternalLinkClient();

        let uploadResult = await this.uploadNewAttachmentsAndImages();

        let title = this.updatedItem.title;
        let summary = this.updatedItem.summary;
        let attachmentIds = uploadResult.allAttachmentIds;
        let imageIds = uploadResult.allImageIds;
        let featuredImageId = uploadResult.newFeaturedImageId;
        let authors = this.updatedItem.authors;
        let externalLinkType = this.updatedItem.externalLinkType;
        let externalLink = this.updatedItem.externalLink;
        let voiceOf = this.updatedItem.voiceOf;
        let userId = this.updatedItem.creator?.userId;
        let promotedBy = this.updatedItem.promotedBy;
        let contentItemId = this.updatedItem.contentItemId;
        await contentClient.updateExternalLink(
          title,
          summary,
          attachmentIds,
          imageIds,
          featuredImageId,
          authors,
          externalLinkType,
          externalLink,
          voiceOf,
          userId,
          promotedBy,
          contentItemId,
        );

        await this.deleteOldAttachmentsAndImages();

        this.$router.go(-1);
      }
    },

    async saveDraft() {
      if (!this.validate()) return;
      this.savingDraft = true;
      await this.save(false);
      this.savingDraft = false;

      this.$router.push({ name: "MyContent" });
    },

    async publishClicked(publishDate: string) {
      if (publishDate != "") this.publishDate = new Date(publishDate);

      this.publishing = true;
      await this.save(true);
      this.publishing = false;

      this.publishDialogOpen = false;
      this.$router.push({ name: "MyContent" });
    },

    deleteClicked() {
      this.deleteDialogOpen = true;
    },
    async deleteConfirmed() {
      this.deleting = true;
      await this.deletePost();
      this.deleting = false;
      this.deleteDialogOpen = false;
      this.$router.push({ name: "MyContent" });
    },

    validate(): boolean {
      this.showValidation = true;
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    setItem(item: DraftUserExternalLinkContentItemResult) {
      this.updatedItem = item;
    },
    setNewAttachments(attachments: File[]) {
      this.newAttachments = attachments;
    },
    setNewImages(images: File[]) {
      this.newImages = images;
    },
    setNewFeaturedImage(featuredImage: File | null) {
      this.newFeaturedImage = featuredImage;
    },
    setDeletedAttachments(deletedAttachmentIds: string[]) {
      this.deletedAttachmentIds = deletedAttachmentIds;
    },
    setDeletedImages(deletedImageIds: string[]) {
      this.deletedImageIds = deletedImageIds;
    },
    setDeletedFeaturedImage(deletedFeaturedImageId: string | null) {
      this.deletedFeaturedImageId = deletedFeaturedImageId;
    },
  },
  computed: {
    ...mapGetters(["userId", "hasVoiceOfRole", "hasAdminRole", "hasSponsorRole"]),

    previewExternalLink() {
      let userExternalLink: IUserExternalLinkContentItemResult = {
        ...this.updatedItem,
        ownerId: this.userId,
        publishedOn: this.updatedItem.publishedOn != undefined ? this.updatedItem.publishedOn : new Date(),
        contentItemType: ContentItemType.UserExternalLink,
      };
      return Object.assign({}, userExternalLink);
    },
  },
  watch: {
    $route(to, from) {},
  },
});
